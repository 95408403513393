import { FormControl, Switch, FormControlLabel, SelectChangeEvent } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { useCreateOrigin, useUpdateOrigin } from "../../common/hook/useOriginData";
import { OriginDTO, Scheme } from "../../common/repository/IOriginRepository";
import dependency from "../../dependency";
import PageLoading from "../../common/components/PageLoading";
import CustomAutocomplete, { OptionType } from "../../common/components/basics/CustomAutocomplete";

export type QuestionnaireType = "name" | "url" | "active" | "weight";

const NAME_VALIDATION = /^[a-zA-Z0-9+\-!? ]+$/;

const schemeSelect = Object.values(Scheme).map((scheme) => ({ label: scheme, value: scheme }));

const initialOrigin: OriginDTO = {
  id: 0,
  name: "",
  url: "",
  active: false,
  scheme: Scheme.http,
  weight: 0,
};

const initialFormError = {
  name: false,
  url: false,
  scheme: false,
  weight: false,
};

export default function OriginInfoView() {
  const [origin, setOrigin] = useState(initialOrigin);
  const [formError, setFormError] = useState(initialFormError);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const { id: origin_id } = useParams();
  const navigate = useNavigate();

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const fetchOrigin = async () => {
    if (origin_id) return await dependency.repositories.originRepository.origin(Number(origin_id));
  };

  const { data } = useQuery("origin", fetchOrigin);

  const { mutateAsync: createOrigin } = useCreateOrigin(onError);
  const { mutateAsync: updateOrigin } = useUpdateOrigin(onError);

  useEffect(() => {
    setOrigin(data || initialOrigin);
  }, [data, setOrigin]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrigin({
      ...origin,
      active: e.target.checked,
    });
  };

  const onChange = (type: QuestionnaireType) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | number>) => {
      const value = e.target.value;

      setOrigin({
        ...origin,
        [type]: type === "weight" ? Number(value) : value,
      });
    };
  };

  const onSchemeChange = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue: OptionType) =>
      setOrigin((prevOrigin) => ({
        ...prevOrigin,
        scheme: newValue.value as Scheme,
      })),
    [setOrigin]
  );

  const areValidInputs = useCallback(
    (name: string, url: string, scheme: Scheme, weight: number) =>
      name.length > 0 && url.length > 0 && name.match(NAME_VALIDATION) && scheme.length > 0 && weight > 0,
    []
  );

  const handleCancel = useCallback(() => navigate("/app/origins"), [navigate]);

  const handleSave = useCallback(async () => {
    const { name, url, active, scheme, weight } = origin;
    if (areValidInputs(name, url, scheme, weight)) {
      setIsSaveClicked(true);
      if (origin_id) {
        await updateOrigin({ id: Number(origin_id), origin: { name, url, active, scheme, weight } });
      } else {
        await createOrigin({ name, url, active, scheme, weight });
      }

      handleCancel();
    } else {
      setFormError({
        name: name.length === 0 || !name.match(NAME_VALIDATION),
        url: url.length === 0,
        scheme: scheme.length === 0,
        weight: weight <= 0,
      });
    }
  }, [origin, origin_id, handleCancel, updateOrigin, createOrigin, setFormError, setIsSaveClicked, areValidInputs]);

  const switchStatus = !origin_id ? (
    <>
      <FormControl variant="outlined" fullWidth>
        <FormControlLabel label="Active" control={<Switch checked={origin.active} onChange={onSwitchChange} />} />
      </FormControl>
      <EmptyRow />
    </>
  ) : (
    <EmptyRow />
  );

  if (isSaveClicked && !errorMessage) return <PageLoading />;

  return (
    <>
      <CustomTextField label="Name" error={formError.name} value={origin.name as string} onChange={onChange("name")} required />
      <EmptyRow />
      <CustomTextField label="Origin URL" error={formError.url} value={origin.url as string} onChange={onChange("url")} required />
      <EmptyRow />
      <CustomAutocomplete value={origin.scheme} onChange={onSchemeChange} error={formError.scheme} options={schemeSelect} label="Scheme" />
      <EmptyRow />
      <CustomTextField
        label="Weight"
        error={formError.weight}
        value={origin.weight > 0 ? origin.weight.toString() : ""}
        onChange={onChange("weight")}
        required
      />
      <EmptyRow />
      {switchStatus}
      <Actions handleCancel={handleCancel} handleSave={handleSave} />
      <CommandError message={errorMessage} />
    </>
  );
}
