import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* @ts-ignore: */}
      <GridToolbarColumnsButton />
      {/* @ts-ignore: */}
      <GridToolbarFilterButton />
      {/* @ts-ignore: */}
      <GridToolbarDensitySelector />
      <Button size="small" startIcon={<Add />} component={Link} to={`/app/lineups/add`}>
        Add lineup
      </Button>
    </GridToolbarContainer>
  );
}
